import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class UserGroupService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/user-group`;
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
                url = `${url}?page=${index}`;
        let param ={
                params: data
        }
        return apiService.query(url,param);
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }
    getStudentIsPresident(){
        let url = `${this.#api}/get/student/is-president`;
        return apiService.get(url);
    }
}
