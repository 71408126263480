<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>{{ groupName }}</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ {{ groupName }}
                                </div>
                            </div>
<!--                            <div class="breadcrumb-right" v-if="isStudentPresident">-->
<!--                                <div class="card-toolbar">-->
<!--                                    <v-btn  class="mt-4 btn btn-primary" style="color: #fff" @click="addUserGroup">-->
<!--                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>-->
<!--                                        Add group-->
<!--                                    </v-btn>&nbsp;&nbsp;-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="card-body">

                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Title</th>
                                        <th class="px-3 wrap-column">Clubs president</th>
                                        <th class="px-3 wrap-column">Description</th>
                                        <th class="pr-3 text-center" v-if="isStudentPresident">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="userGroups.length > 0" v-for="(item, index) of userGroups" :key="index">
                                        <td class="px-3 wrap-column">
                                            <a class="custom-poniter" @click="viewMembers(item.unique_id)">{{ item.title }}</a>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <p><b>President one: </b> {{item.president_one || 'N/A'}}</p>
                                            <p> <b>President two: </b> {{item.president_two || 'N/A'}}</p>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div v-if="item.description" v-html="item.description"></div>
                                            <div v-else>NA</div>
                                        </td>
                                        <td class="pr-0 text-center" v-if="isStudentPresident">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="viewMembers(item.unique_id)">
                                                            <span class="navi-icon">
                                                                <i class="fas fa-users"></i>
                                                            </span>
                                                            <span class="navi-text">Member</span>
                                                        </a>
                                                    </b-dropdown-text>

                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="userGroups.length == 0">
                                        <td class="text-center  px-3" colspan="3">
                                          <strong>No data available to display.</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>

import UserGroupService from "@/core/services/user/group/UserGroupService";
import studentMixin from "@/mixins/StudentMixin";

const userGroup = new UserGroupService();

export default {
  name:"group",
  mixins: [studentMixin],
  data() {
    return {
      search: {
          title: '',
      },
      loading: false,
      userGroups: [],
    }
  },
  mounted() {
    this.getUserGroups();
  },
  methods: {
    searchUserGroups(){
        this.page = 1;
        this.getUserGroups();
    },
    getUserGroups() {
        this.loading = true;
        userGroup
            .paginate(this.search, this.page)
            .then(response => {
                this.userGroups = response.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    },

    addUserGroup() {
        this.$router.push(
            {
                name: 'user-group-create'
            }
        );
    },

    viewMembers(userGroupId){
        this.$router.push(
            {
                name: 'user-group-member',
                params:{
                    userGroupId: userGroupId
                }
            }
        );
    },
  },
}
</script>
